import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// Components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Logo
import logoMn from '../../assets/company info/Undesnii-zuvlugch-white.png';
import logoEn from '../../assets/company info/Undesnii-zuvlugch-white-ENG.png'

// Icon
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import RoomSharpIcon from '@mui/icons-material/RoomSharp';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setCompany } from '../../app/slices/company_slice';

// Test data 
import { company_fblc } from '../../data/base';
import { company_tbb } from '../../data/base';
import { company_ijob } from '../../data/base';
import { company_dhh, company_dhh_en } from '../../data/base';

// Map
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';

// Footer and header
import CustomFooter from '../Footer';
import Header from '../Header';

function NewsPage() {
    // Access the companyId parameter from the URL
    const { companyName } = useParams();
    // const [ data, setCompany] = useState(null);
    // const { data, setData } = useState(null);

    // Load the Nconsult data
    let data = useSelector((state) => state.company.value);
    const language = useSelector((state) => state.app.value.language);
    const dispatch = useDispatch();

    // Generated components
    const [baseComponents, setBaseComponents] = useState([]);

    // Initial test data loaders
    useEffect(() => {
        // setCompany(null);
        if (companyName == 'fblc') {
            // setCompany(company_fblc);
            dispatch(setCompany(company_fblc));
        } else if (companyName == 'tbb') {
            // setCompany(company_tbb)
            dispatch(setCompany(company_tbb));
        } else if (companyName == 'ijob') {
            // setCompany(company_ijob)
            dispatch(setCompany(company_ijob));
        } else if (companyName == 'dhh') {
            if(language == 'en') {
                // setCompany(company_dhh_en)
            // setBaseComponents([]);
                dispatch(setCompany(company_dhh_en));
            }
            else 
            // setCompany(company_dhh)
                dispatch(setCompany(company_dhh));
        }
        // setChanged(true);
    }, [language]);

    // Map options [disable touch, and]
    const mapOptions = {
        doubleClickZoom: false,
        closePopupOnClick: false,
        dragging: false,
        zoomSnap: false,
        zoomDelta: false,
        trackResize: false,
        touchZoom: false,
        scrollWheelZoom: false
    }



    // MICRO components    
    const build_title = (item, id) => {
        if(item=='Холбоо барих' || item == ''){
            return null;
        }
        return <Typography key={item + id} variant="h6" sx={{ fontWeight: 'bold', mb: 2, mt: 6 }}>
            {item}
        </Typography>
    }

    // MACRO components
    const build_list = (items, id) => {
        return (
            <Box key={'build_list' + id} sx={{ width: '80%', pl: 4, 
                    ml: { xs: 0, sm: 1, md: 3, lg: 4, xl: 4} ,
                    mt: 2
                }} 
            >
                {
                    items.map(
                        (item) =>
                            <Typography key={item + '_key'} sx={{mt:1}}>
                                • {item}
                            </Typography>
                    )
                }
            </Box>
        );
    }

    const build_contact = (items) => {
        return <Box key={'build_contact'} sx={{ width: '90%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '5%', pt: '2rem' }} >
            {
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                        <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                            <LocalPhoneSharpIcon />
                            <Typography sx={{ ml: '5%' }}>
                                {items.mobile}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                            <EmailSharpIcon />
                            <Typography sx={{ ml: '5%' }}>
                                {items.email}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                            <RoomSharpIcon />
                            <Typography sx={{ ml: '5%' }}>
                                {items.address}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', height: '250px', width: '90%' }} >
                        <MapContainer
                            style={{ height: '100%', width: '100%' }}
                            center={[51.505, -0.09]} zoom={13} dragging='false' scrollWheelZoom='false'
                            {...mapOptions}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[51.505, -0.09]}>
                                <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Box>
                </Box>
            }
        </Box>
    }

    // Consultants
    const build_consultants = (items, id) => {
        return (
            <Box key={'build_consultants' + id} sx={{ width: '100%', flexGrow: 1 }} >

                <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} justifyContent="center">
                    {
                        items.map(
                            (consultant, i) =>
                                <Grid key={i + '_' + consultant.consultant_id} item sx={{
                                    width: { xs: '50%', sm: '44%', md: '33%', lg: '25%', xl: '20%' }
                                }}
                                >
                                    <Box sx={{ 
                                        height:  { xs: '18rem', sm: '18rem', md: '18rem', lg: '18rem', xl: '18rem', },
                                        backgroundColor: '#1b3b48', borderTopLeftRadius: '2rem', borderBottomRightRadius: '2rem' }}
                                    >
                                        { /* Pic */}
                                        <Box key={i + '_' + 'profile'} sx={{ height: '12rem', alignItems: "center", display: 'flex', flexDirection: 'column', justifyContent: "center", }}
                                        >
                                            <Box component="img"
                                                alt="logo"
                                                src={consultant.profile_img}
                                                sx={{ mt: '10%', mb: '10%', height: 'auto', maxHeight: '80%', width: 'auto', maxWidth: '100%', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}
                                            />
                                        </Box>

                                        { /* Title and name */}
                                        <Box key={i + '_' + 'title_and_name'} sx={{
                                            alignItems: "center",
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: "center",
                                        }}
                                        >
                                            <Typography key={i + '_' + 'displayName'} sx={{ fontWeight: 'bold', color: 'white' }} align="center" >
                                                {consultant.displayName_cyrillic}
                                            </Typography>

                                            <Typography key={i + '_' + 'title'} sx={{ color: 'white', pl: 1, pr: 1 }} align="center" >
                                                {consultant.title_cyrillic}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Grid>
                        )
                    }
                </Grid>
            </Box>
        );
    }

    const build_text = (item, id) => {
        return <Box key={'build_text' + id} sx={{ width: '100%', align: 'justify' }}>
            <Typography sx={{ textAlign: 'justify' }} >
                    {item}
            </Typography>
        </Box>
    }

    //
    // BASE component
    //
    const build_components = () => {
        if (data.length === undefined) {
            const tempComps = [];

            // First order it
            // data.content.sort((a, b) => a.order - b.order);
            data.content.forEach((item) => {
                // Title
                tempComps.push(build_title(item.title, item.order));
                switch (item.type) {
                    case 'text':
                        tempComps.push(build_text(item.content, item.order));
                        break;
                    case 'list':
                        tempComps.push(build_list(item.content, item.order));
                        break;
                    case 'consultant':
                        tempComps.push(build_consultants(item.content, item.order));
                        break;
                    //case 'contact':
                    //    tempComps.push(build_contact(item.content));
                    //    break;
                    default:
                        console.log('default?');
                }
            });

            setBaseComponents(tempComps);
        }
    }

    useEffect(() => {
        build_components();
    }, [data]);

    if (data.length === 0)
        return (<></>);

    return (
        <Box sx={{
            backgroundColor: '#1b3b48',
            width: '100%'
        }}>
            <Header pageName={"company"} />

            {
                //******************** Top app bar *********************
            }
            <Box key='top_app_bar' sx={{
                height: { xs: '10%', sm: '10%', md: '12%', lg: '13%', xl: '5%' },
                ml:    { xs: '2%'},
                width: '100%',
                alignItems: "center",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
            }}
            >
                <Box
                    component="img"
                    alt="logo"
                    src={logoMn}
                    sx={{
                        mt: 2,
                        pb: 2,
                        width: 'auto',
                        maxWidth: {
                            xs: '20%',
                            sm: '16%',
                            md: '13%',
                            lg: '10%',
                            xl: '9%'
                        },
                    }}
                />
            </Box>

            {
                //******************** Body *********************
            }
            <Box key='body' sx={{
                height: '80%',
                width: { xs: '100%', sm: '96%', md: '80%', lg: '80%', xl: '80%' },
                mx:    { xs: '0%', sm: '2%', md: '10%', lg: '10%', xl: '10%' },
                ml:    { xs: '2%'},
                backgroundColor: '#ffffff',
                borderRadius: '3rem',
                display: 'flex',
                flexDirection: 'column',
                px: { xs: '3%', sm: '3%', md: '3%', lg: '3%', xl: '3%' },
            }}>
                {
                    //******************** Company: Title *********************
                }
                <Box key='title' sx={{
                    width: '100%',
                    pl: {
                        xs: data.company_logo !== '' ? '' : '2%',
                        sm: data.company_logo !== '' ? '' : '2%',
                        md: data.company_logo !== '' ? '' : '2%',
                        lg: data.company_logo !== '' ? '' : '1.5%',
                        xl: data.company_logo !== '' ? '' : '1.5%'
                    },
                    height: {
                        xs: data.company_logo !== '' ? '10rem' : '10rem',
                        sm: data.company_logo !== '' ? '13rem' : '11rem',
                        md: data.company_logo !== '' ? '15rem' : '12rem',
                        lg: data.company_logo !== '' ? '18rem' : '13rem',
                        xl: data.company_logo !== '' ? '22rem' : '14rem'
                    },
                    display: 'flex', flexDirection: 'row'
                }}
                >
                    {
                        data.company_logo !== ''
                            ? <Box sx={{
                                my: '2%',
                                ml: { xs: '5%', sm: '9%', md: '10%', lg: '10%', xl: '18%' },
                                width: { xs: '25%', sm: '21%', md: '19%', lg: '19%', xl: '15%' },
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: { xs: '10rem', sm: '10rem', md: '15rem', lg: '18rem', xl: '22rem' },
                            }}
                            >
                                <Box
                                    component="img"
                                    alt="logo"
                                    src={data.company_logo}
                                    sx={{
                                        height: 'auto',
                                        width: '100%',
                                    }}
                                />
                            </Box>
                            : <></>
                    }
                    <Box key='logo'
                        sx={{
                            width: {
                                xs: data.company_logo !== '' ? '70%' : '100%',
                                sm: data.company_logo !== '' ? '70%' : '100%',
                                md: data.company_logo !== '' ? '70%' : '100%',
                                lg: data.company_logo !== '' ? '60%' : '100%',
                                xl: data.company_logo !== '' ? '50%' : '100%'
                            },
                            alignItems: "center",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center'}}>
                        {data.name_en}
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center'}}>
                        {data.name_mn}
                        </Typography>

                    </Box>
                </Box>

                {
                    //******************** Company: description *********************
                }
                <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', ml: '5%', mr: '5%' }} >

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' } }}>
                        <Box sx={{
                            width: {
                                xs: 'auto',
                                sm: 'auto',
                                md: data.people_img !== '' ? '70%' : '100%',
                                lg: data.people_img !== '' ? '70%' : '100%',
                                xl: data.people_img !== '' ? '70%' : '100%'
                            },
                            align: 'justify'
                        }}
                        >
                            <Typography sx={{ textAlign: 'justify', whiteSpace: 'pre-line' }} >
                                {
                                    data.description.main
                                }
                            </Typography>
                        </Box>

                        {
                            data.people_img !== ''
                                ? <Box sx={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: { xs: 'auto', sm: 'auto', },
                                    width: { xs: '100%', sm: '100%', md: '100%', lg: '80%', xl: '80%' },
                                    pl: { xs: '0', sm: '0%', md: '1%', lg: '1%', xl: '5%' }
                                }}>
                                    <Box component="img" alt="logo" src={data.people_img} 
                                        sx={{ 
                                            width:  '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }} 
                                    />
                                </Box>
                                : <></>
                        }
                    </Box>


                    <Box sx={{ mt: 2, mb: 2, width: 'auto', align: 'justify' }} >
                        <Typography sx={{ textAlign: 'justify', whiteSpace: 'pre-line' }} >
                            {
                                data.description.sub
                            }
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '5%' }} >
                    {
                        // Contents
                        baseComponents.map((comp) => comp)
                    }
                </Box>

                {
                    // Just a margin
                }
                <Box sx={{ width: '100%', mb: '5%' }} />
            </Box>

            <CustomFooter />

        </Box>
    );
}

export default NewsPage;
