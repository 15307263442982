import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Button, CardActionArea, Dialog, DialogContent, CardActions, Container } from '@mui/material';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../app/slices/appSlice';

// Responsive widgets
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// import data
import {company_list} from '../data/base';

const theme = createTheme();

theme.typography.h5 = {
	[theme.breakpoints.up('xs')]: {
		fontSize: '1rem',
	},
	[theme.breakpoints.up('sm')]: {
		fontSize: '1.7rem',
	},
	[theme.breakpoints.up('md')]: {
		fontSize: '1.7rem',
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '1.4rem',
	},
	[theme.breakpoints.up('xl')]: {
		fontSize: '22px',
	},
};

let selection = 0;

function CompCompanies(props) {

	// For language selection
	const language = useSelector((state) => state.app.value.language);
	const dispatch   = useDispatch();

	const navigate = useNavigate();

	const handleOpen = (id) => {
		selection = id - 1;
		switch(id){
			case '1':
				navigate(`/company/fblc`);
				break;
			case '2':
				navigate(`/company/tbb`);
				break;
			case '3':
				navigate(`/company/dhh`);
				// navigate(`/company/ijob`);
				break;
			default:
				break;
		}
	};

	const GeneralSectors = (props) => {
		return (
			<Box
				sx={{
					width: { xs: '100%', sm: '100%', md: '80%', lg: '30%', xl: '30%' },
					flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
					position: 'relative',
					m: 2
				}}
			>
				<Card elevation={2}
					sx={{
						height: {
							xs: '13rem',
							sm: '13rem',
							md: '15rem',
							lg: '15rem',
							xl: '15rem'
						},
						borderRadius: '20px',
						width: { xs: '90%', sm: '90%', md: '90%', lg: '90%', xl: '90%', },
						ml: { xs: '12%', sm: '12%', md: '12%', lg: '12%', xl: '12%' },
					}}
				>
					<CardActionArea sx={{ height: '100%', position: 'relative' }} onClick={() => { handleOpen(props.item.item.id) }} >

						<CardContent>
							<ThemeProvider theme={theme}>
								<Typography gutterBottom variant="h5" component="div" sx={{ ml: 10 }} >
									{props.item.item.title[language]}
								</Typography>
								<Typography variant="body2" color="text.secondary" sx={{ ml: 10 }} >
									{props.item.item.desc}
								</Typography>
							</ThemeProvider>
						</CardContent>
					</CardActionArea>
				</Card>

				<Box
					sx={{
						ml: { xs: '0', sm: '2%', md: '5%', lg: '0', xl: '1%' },
						width: { xs: '100px', sm: '110px', md: '120px', lg: '120px', xl: '27%' },
						position: 'absolute',
						top: '10%',
					}}
				>
					{
						<Card
							sx={{
								height: {
									xs: '10rem',
									sm: '12rem',
									md: '12rem',
									lg: '10rem',
									xl: '12rem'
								},
								width: {
									xs: '8rem',
									sm: '9rem',
									md: '8rem',
									lg: '7rem',
									xl: '7rem'
								},
								borderRadius: '28px',
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								bgcolor: props.item.item.id==2 ? '#23408e' : '#ffffff'
							}}
						>
							{console.log("props.item.item.comps[0]",props.item.item.comps[0])}
							<CardMedia
								component="img"
								image={props.item.item.comps[0].logo ? props.item.item.comps[0].logo : require(`/src/assets/partners_logo/${props.item.item.comps[0].logo_static}`)}
								alt={props.item.item.comps[0].name}
								sx={{
									height: '70%',
									width: '100%',
								}}
							/>

						</Card>

					}

				</Box>

			</Box>
		);
	}

	return (
		<Box width={'100%'} justifyContent="center" alignItems="center" display="flex"
			sx={{
				flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
				mt: 2,
				mb: 2
			}}
		>
			{
				company_list.map((item, i) => <GeneralSectors key={i + 'CustomCard'} item={{ 'item': item, 'ind': i }} />)
			}
		</Box>
	);
}

export default CompCompanies;