import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// Components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Logo
import logoMn from '../../assets/company info/Undesnii-zuvlugch-white.png';
import logoEn from '../../assets/company info/Undesnii-zuvlugch-white-ENG.png'

// Icon
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import RoomSharpIcon from '@mui/icons-material/RoomSharp';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Test data
import { batzorig } from '../../data/base';
import { batjargal } from '../../data/base';
import { tungalagnaran } from '../../data/base';
import { tsetsgee } from '../../data/base';
import { enkhjargal } from '../../data/base';
import { tsengelbaatar } from '../../data/base';

// Map
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';

// Header
import Header from '../Header';
// Footer
import CustomFooter from '../Footer';
import { setPerson } from '../../app/slices/person';

function ConsultantPage(){
    // Access the companyId parameter from the URL
    const { consultantName } = useParams();

    // Load the Nconsult data
    let data = useSelector(state => state.person.value);
    let app  = useSelector(state => state.app.value);
    const dispatch = useDispatch();

    // Initial test data loaders
    useEffect(() => {
    
        if (consultantName === "batzorig") {
            dispatch( setPerson(batzorig) );
        } else if (consultantName === "batjargal"){
            dispatch( setPerson(batjargal) );
        } else if (consultantName === "tungalagnaran"){
            dispatch( setPerson(tungalagnaran) );
        } else if (consultantName === "tsetsgee"){
            dispatch( setPerson(tsetsgee) );
        } else if (consultantName === "enkhjargal"){
            dispatch( setPerson(enkhjargal) );
        } else if (consultantName === "tsengelbaatar"){
            dispatch( setPerson(tsengelbaatar) );
        }
    },[]);

    // Generated components
    const [ baseComponents, setBaseComponents ] = useState([]); 

    // MICRO components    
    const build_title = (item) => {
        return <Typography sx={{ fontWeight: 'bold', mb: 2, mt:4 }}>
            {item}
        </Typography>
    }

    // MACRO components
    const build_list = (items) => {
        return (
            <Box sx={{ width: '80%', pl: 4 }} >
                {
                    items.map(
                        (item) =>
                            <Typography>
                            {item}
                            </Typography>
                    )
                }
            </Box>
        );
    }
    const build_list_desc = (items) => {
        return (
            <Box sx={{ width: '80%', pl: 4 }} >
                {
                    items.map(
                        (item) =>
                            <>
                                <Typography>
                                {" • " + item.heading}
                                </Typography>
                                <Box sx={{ m: 4}} >
                                    {
                                        item.subheading.map(
                                            desc => <Typography>
                                                { desc }
                                            </Typography>  
                                        )
                                    }
                                </Box>
                            </>
                    )
                }
            </Box>
        );
    }
    const build_contact = (items) => {
        return <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '5%', pt: '2rem' }} >
            {  
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                        <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                            <LocalPhoneSharpIcon />
                            <Typography sx={{ ml: '5%' }}>
                                {items.mobile}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                            <EmailSharpIcon />
                            <Typography sx={{ ml: '5%' }}>
                                {items.email}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                            <RoomSharpIcon />
                            <Typography sx={{ ml: '5%' }}>
                                {items.address}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', height: '250px', width: '90%' }} >
                        <MapContainer
                            style={{ height: '100%', width: '100%' }}
                            center={[51.505, -0.09]} zoom={13} dragging='false' scrollWheelZoom='false'
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[51.505, -0.09]}>
                                <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Box>
                </Box> 
            }
        </Box>
    }

    // Consultants
    const build_consultants = (items) => {
        return (
            <Box sx={{ width: '100%', flexGrow: 1}} >

                <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} >
                    {
                        items.map(
                            (consultant, i) =>
                                <Grid item sx={{
                                    width: { xs: '50%', sm: '33%', md: '25%', lg: '20%', xl: '16%' }
                                }}
                                >
                                    <Box sx={{ height: '18rem', backgroundColor: '#1b3b48', borderTopLeftRadius: '2rem', borderBottomRightRadius: '2rem'}}
                                    >
                                        { /* Pic */}
                                        <Box sx={{ height: '12rem', alignItems: "center", display: 'flex', flexDirection: 'column', justifyContent: "center",}}
                                        >
                                            <Box component="img"
                                                alt="logo"
                                                src={consultant.profile_img}
                                                sx={{ mt: '10%', mb: '10%', height: 'auto', maxHeight: '80%', width: 'auto', maxWidth: '100%', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem'}}
                                            />
                                        </Box>

                                        { /* Title and name */}
                                        <Box sx={{
                                            alignItems: "center",
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: "center",
                                        }}
                                        >
                                            <Typography sx={{ fontWeight: 'bold', color: 'white' }} align="center" >
                                                {consultant.displayName}
                                            </Typography>

                                            <Typography sx={{ color: 'white', pl: 1, pr: 1 }} align="center" >
                                                {consultant.title}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Grid>
                        )
                    }
                </Grid>
            </Box>
        );
    }
    const build_text = (item) => {
        return <Box sx={{ width: '100%', align: 'justify' }}>
            <Typography sx={{ textAlign: 'justify' }} >
                { 
                    item
                }
            </Typography>
        </Box>
    }

    //
    // BASE component
    //
    const build_components = () => {
        if(data != undefined){    
            const tempComps = [];

            // First order it
            // data.content.sort((a, b) => a.order - b.order);
            
            data.content.forEach((item)=> {
                // Title
                if(app.language === 'mn'){
                    tempComps.push( build_title(item.title_mn) );
                }else if(app.language === 'en'){
                    tempComps.push( build_title(item.title_en) );
                }
                
                switch(item.type){
                    case 'text':
                        if(item.content_mn.length == 0){
                            tempComps.push( build_text(item.content_en) );
                        }else{
                            tempComps.push( build_text(item.content_mn) );
                        }
                        //if(app.language == 'mn'){
                        //    tempComps.push( build_text(item.content_mn) );
                        //}else if(app.language == 'en'){
                        //    tempComps.push( build_text(item.content_en) );
                        //}else{
                        //
                        //}
                        break;
                    case 'list':
                        if(item.content_mn.length == 0){
                            tempComps.push( build_list(item.content_en) );
                        }else{
                            tempComps.push( build_list(item.content_mn) );
                        }
                        /*
                        if(app.language == 'mn'){
                            tempComps.push( build_list(item.content_mn) );
                        }else if(app.language == 'en'){
                            tempComps.push( build_list(item.content_en) );
                        }else{

                        }*/
                    
                        break;
                    case 'consultant':
                        if(item.content_mn.length == 0){
                            tempComps.push( build_consultants(item.content_en) );
                        }else{
                            tempComps.push( build_consultants(item.content_mn) );
                        }
                        /*
                        if(app.language == 'mn'){
                            tempComps.push( build_consultants(item.content_mn) );
                        }else if(app.language == 'en'){
                            tempComps.push( build_consultants(item.content_en) );
                        }else{

                        }*/   
                        
                        break;
                    case 'contact':
                        if(item.content_mn.length == 0){
                            tempComps.push( build_contact(item.content_en) );
                        }else{
                            tempComps.push( build_contact(item.content_mn) );
                        }
                        /*
                        if(app.language == 'mn'){
                            tempComps.push( build_contact(item.content_mn) );
                        }else if(app.language == 'en'){
                            tempComps.push( build_contact(item.content_en) );
                        }else{

                        }*/
                        
                        break;
                    case 'list_desc':
                        if(item.content_mn.length == 0){
                            tempComps.push( build_list_desc(item.content_en) );
                        }else{
                            tempComps.push( build_list_desc(item.content_mn) );
                        }
                        /*
                        if(app.language == 'mn'){
                            tempComps.push( build_list_desc(item.content_mn) );
                        }else if(app.language == 'en'){
                            tempComps.push( build_list_desc(item.content_en) );
                        }else{

                        }*/
                        
                        break;
                    default:
                        console.log('default?');      
                }
            });

            setBaseComponents(tempComps);
            console.log(tempComps);
        }
    }

    useEffect(()=>{
        build_components();
    },[data]);
    
    //Main builder
    return (
        <Box sx={{
            backgroundColor: '#1b3b48',
            width: '100%'
        }}>
            <Header pageName={"company"} />
            {
                //******************** Top app bar *********************
            }
            <Box key='top_app_bar' sx={{
                    height: { xs: '10%', sm: '10%', md: '12%', lg: '13%', xl: '5%' },
                    ml:    { xs: '2%'},
                    width: '100%',
                    alignItems: "center",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                }}
                >
                    <Box
                        component="img"
                        alt="logo"
                        src={logoMn}
                        sx={{
                            mt: 2,
                            pb: 2,
                            width: 'auto',
                            maxWidth: {
                                xs: '20%',
                                sm: '16%',
                                md: '13%',
                                lg: '10%',
                                xl: '9%'
                            },
                        }}
                    />
                </Box>
            {
                //******************** Body *********************
            }
            <Box sx={{
                width: { xs: '100%', sm: '96%', md: '80%', lg: '80%', xl: '80%' },
                mx:    { xs: '0%', sm: '2%', md: '10%', lg: '10%', xl: '10%' },
                ml:    { xs: '2%'},
                backgroundColor: '#ffffff',
                borderRadius: '3rem',
                display: 'flex',
                flexDirection: 'column',
                px: { xs: '0%', sm: '3%', md: '3%', lg: '3%', xl: '3%' },
            }}>
                {
                    //******************** Person: General info and picture *********************
                }
                <Box sx={{
                    height: { 
                        xs: 'auto', 
                        sm: 'auto', 
                        md: '18rem', 
                        lg: '17rem', 
                        xl: '16rem' 
                    },
                    mt : '2%',
                    pl: '5%', pr: '5%',
                    display: 'flex',
                    flexDirection:  {  xs: "column", sm: "column", md: "row", lg: "row", xl: "row", },
                    justifyContent: {  xs: "center", sm: "center", md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }, 
                    alignItems:     "center"
                }}
                >
                    
                    <Box
                        component="img"
                        alt="logo"
                        src={data.profile_img}
                        sx={{
                            height: { 
                                xs: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "100%",
                                xl: "100%",
                            },
                            objectFit: 'cover',
                            maxWidth: { 
                                xs: "60%",
                                sm: "50%",
                                md: "100%",
                                lg: "100%",
                                xl: "100%",
                            },
                            borderRadius: "5%",
                        }}
                    />  
                    
                    <Box
                        sx={{
                            width: { xs: '100%', sm: '100%', md: '70%', lg: '70%', xl: '70%' },
                            ml: { xs: '', sm: '', md: '5%', lg: '5%', xl: '5%' },
                            mt: { xs: '30px', sm: '', md: '', lg: '', xl: '' },
                            alignItems: "center",
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "center",
                        }}
                    >
                        <Box sx={{ 
                                width: { xs: '35%', sm: '65%', md: '65%', lg: '55%', xl: '45%' },
                                display: 'flex', flexDirection : 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                alignContent: 'flex-start',
                            }} 
                        >
                            <Typography>
                                {   
                                    app.language == 'mn'  ? "Нэр" : "First name"
                                }
                            </Typography>
                            <Typography>
                                {
                                    app.language == 'mn' ? "Овог": "Last name"
                                }
                            </Typography>
                            <Typography>
                                { 
                                    app.language == 'mn'? "Албан тушаал": "Current role"
                                }
                            </Typography>
                        </Box>
                        <Box sx={{ 
                            width: { xs: '60%', sm: '70%', md: '70%', lg: '60%', xl: '50%' },
                            display: 'flex', flexDirection : 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                        }}>
                            <Typography>
                                {
                                    app.language == 'mn' ? data.firstName_cyrillic: data.firstName_latin
                                }
                            </Typography>
                            <Typography>
                                {
                                    app.language == 'mn' ? data.lastName_cyrillic: data.lastName_latin
                                }
                            </Typography>
                            <Typography>
                                { 
                                    app.language == 'mn'? data.title_cyrillic: data.title_latin 
                                }
                            </Typography>
                        </Box>
                    </Box>
                    
                </Box>

                {
                    //******************** Person: CV *********************
                }
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '' }} >
                    {
                        // Contents
                        baseComponents.map((comp) => comp)
                    }
                </Box>
                
                {
                    // Just a margin
                }
                <Box sx={{width: '100%', mb: '70px'}} />
            </Box>

            <CustomFooter />

        </Box>
    );
}

export default ConsultantPage;

